.offers {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto 120px;

  ul {
    list-style-type: "- ";
  }

  &__grid {
    @include breakpoint(small only) {
      padding: 0 20px;
    }
  }

  &__item {
    margin-bottom: 80px;

    @include breakpoint(small only) {
      margin-bottom: 60px;
    }

    &-content {
      background-color: get-color("green-hint");
      padding: 42px 21px;

      @include breakpoint(large) {
        padding: 42px;
      }

      & > div {
        height: 100%;
      }

      h2 {
        @include tertiary-heading();
        color: get-color("mid-green");
        margin-bottom: 30px;

        a {
          color: inherit;
        }
      }

      h3 {
        @include price;
        color: get-color("mid-green");
      }

      p {
        @include body-copy();
        color: get-color("dark-green");
      }

      ul {
        margin: 0 0 21px;
        list-style: none;

        @include breakpoint(small only) {
          margin-bottom: 18px;
        }
      }

      li {
        @include bullet-list();
        margin-bottom: 0;
        color: get-color("dark-green");
        font-weight: 500;
        padding-left: 12px;
        position: relative;
        font-size: 13px;
        margin-bottom: 15px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 4px;
          height: 2px;
          background-color: get-color("mid-green");
          transform: translateY(-50%);
        }
      }

      p:last-child,
      li:last-child {
        margin-bottom: 0;
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include breakpoint(medium) {
        padding-right: 40px;
      }

      color: get-color("dark-green");
    }

    &-price {
      @include breakpoint(small only) {
        padding-bottom: 15px;
      }

      @include breakpoint(medium) {
        padding-top: 10px;
        padding-left: 21px;
      }
    }

    .multiple-buttons {
      @include breakpoint(medium) {
        padding-left: 21px;
        display: flex;
        align-items: flex-end;

        .button {
          display: block;
          margin: 20px 0 0;
          text-align: left;

          &:first-child {
            margin-top: auto;
          }
        }
      }

      @include breakpoint(small only) {
        margin-top: 40px;
      }
    }

    &-image {
      position: relative;
      min-height: 323px;

      @include breakpoint(small only) {
        z-index: -1;
      }

      @include breakpoint(medium) {
        a,
        picture {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
