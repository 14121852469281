@mixin slider-caption {
  font-family: $primary-font;
  font-size: 55px;
  line-height: 60px;
  font-weight: 700;

  @include breakpoint(medium) {
    font-size: 80px;
    line-height: 86px;
  }
}

@mixin slider-caption-orange {
  font-family: $primary-font;
  font-size: 55px;
  line-height: 60px;
  font-weight: 700;
  color: get-color("orange");

  @include breakpoint(medium) {
    font-size: 110px;
    line-height: 100px;
  }
}

@mixin slider-copy {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

@mixin page-heading {
  font-family: $primary-font;
  font-size: 40px;
  line-height: 36px;
  font-weight: 700;

  @include breakpoint(medium) {
    font-size: 68px;
    line-height: 70px;
  }
}

@mixin subheading {
  font-family: $primary-font;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

@mixin body-copy {
  font-family: $secondary-font;
  font-size: 13px;
  line-height: 19px;
}

@mixin h2 {
  font-family: $primary-font;
  font-size: 40px;
  line-height: 42px;
  font-weight: 700;

  @include breakpoint(medium) {
    font-size: 50px;
    line-height: 55px;
  }
}

@mixin tertiary-heading {
  font-family: $primary-font;
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
}

@mixin price {
  font-family: $primary-font;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
}

@mixin bullet-list {
  font-family: $secondary-font-head;
  font-size: 13px;
  line-height: 19px;
}

@mixin quote {
  font-family: $secondary-font;
  font-size: 20px;
  line-height: 34px;
}

@mixin super-header {
  font-family: $primary-font;
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;

  @include breakpoint(medium) {
    font-size: 80px;
    line-height: 1;
  }
}

@mixin terms {
  font-family: $secondary-font;
  font-size: 11px;
}
