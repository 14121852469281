@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
  primary: #535a52,
  // very dark grey/geeen
  secondary: #767676,
  // default
  success: #3adb76,
  // default
  warning: #ffae00,
  // default
  alert: #cc4b37,
  // default
  "black": #000,
  "white": #fff,
  "grey": #707070,
  "light-grey": #f0f0f0,
  "orange": #e9a94e,
  "pink": #e3b8b1,
  "green-hint": #e7e7df,
  "pale-green": #cfd0bf,
  "extra-pale-green": #cfd0c0,
  "mid-green": #818f66,
  "extra-mid-green": #5f6e4f,
  "dark-green": #00271e,
);

$body-background: #e7e7df;

$primary-font: "Bluu-Next", serif;
$primary-font-bold: "Bluu-Next-Bold", serif;

$secondary-font: "Sequel-Sans-Body", sans-serif;
$secondary-font-light-italic: "Sequel-Sans-Light-Obl-Disp", sans-serif;
$secondary-font-head: "Sequel-Sans-Head", sans-serif;
$secondary-font-roman: "Sequel-Sans-Roman", sans-serif;
