//
//  BLUU NEXT
//

@font-face {
  font-family: 'Bluu-Next';
  src: url('fonts/bluu-next/BluuNext-BoldItalic.woff2') format('woff2'),
      url('fonts/bluu-next/BluuNext-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bluu-Next';
  src: url('fonts/bluu-next/BluuNext-Titling.woff2') format('woff2'),
      url('fonts/bluu-next/BluuNext-Titling.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bluu-Next';
  src: url('fonts/bluu-next/BluuNext-Bold.woff2') format('woff2'),
      url('fonts/bluu-next/BluuNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bluu-Next-Bold';
  src: url('fonts/bluu-next/BluuNext-Bold.woff2') format('woff2'),
      url('fonts/bluu-next/BluuNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//
// SEQUEL SANS
//

//OBL DISP
@font-face {
  font-family: 'Sequel-Sans-Light-Obl-Disp';
  src: url('fonts/sequel/SequelSans-LightOblDisp.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-LightOblDisp.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

//HEAD
@font-face {
  font-family: 'Sequel-Sans-Head';
  src: url('fonts/sequel/SequelSans-BookHead.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-BookHead.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

//BODY
@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-LightBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-LightBody.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-LightOblBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-LightOblBody.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-BookBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-BookBody.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-ObliqueBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-ObliqueBody.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-MediumBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-MediumBody.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-MediumOblBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-MediumOblBody.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-SemiBoldBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-SemiBoldBody.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-SemiBoldOblBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-SemiBoldOblBody.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-BoldBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-BoldBody.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sequel-Sans-Body';
  src: url('fonts/sequel/SequelSans-BoldOblBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-BoldOblBody.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

//ROMAN
@font-face {
  font-family: 'Sequel-Sans-Roman';
  src: url('fonts/sequel/SequelSans-RomanBody.woff2') format('woff2'),
      url('fonts/sequel/SequelSans-RomanBody.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}