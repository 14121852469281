.header {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $body-background;
  transition: background-color 0.3s ease-in-out;

  @include breakpoint(small only) {
    box-shadow: 0 0 20px rgba(#000, 0.3);
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 90px;
    transition: all 0.3s ease-in-out;
  }

  &__open-off-canvas {
    position: absolute;
    top: 21px;
    left: 42px;
    width: 31px;
    height: 42px;
    cursor: pointer;
    border: 0 !important;
    background-color: transparent !important;

    @media screen and (max-width: $offcanvas-breakpoint) {
      top: 18px;
      left: 18px;
    }

    svg {
      rect {
        transition: all 0.3s ease-in-out;
        stroke: get-color("dark-green");
      }
    }

    &:hover {
      svg {
        rect {
          &:first-of-type {
            transform: translateY(-10px);
          }

          &:last-of-type {
            transform: translateY(10px);
          }
        }
      }
    }
  }

  &__gift-vouchers {

    cursor: pointer;
    display: block;
    font-family: Bluu-Next, serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    padding: 12px 16px 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: center;
    transition: all .3s ease-in-out;
    z-index: 1000000;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 7px 20px;
    
    a {
      display: flex
;
    align-items: center;
    gap: 5px;
    color: white;
    }

    @include breakpoint(medium) {
      display: none;
    }
  }

  &__booking {
    position: absolute;
    top: 21px;
    right: 42px;
    margin: 0;


    @media screen and (max-width: $offcanvas-breakpoint) {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 59px;
      background-color: get-color("dark-green");
      border: none;
      text-align: center;
      gap: 10px;
      padding-right: 10px;
    }
  }

  &__gift {

    path {
      fill: get-color('dark-green')
    }

    @include breakpoint(medium) {
      display: none;
    }

    position: absolute;
    right: 30px;
    top: 30px;
    transform: scale(1.1);
  }

  &__open-booking {
    z-index: 1000000;
    text-align: center;
    display: block;
    border: solid 1px get-color("dark-green");
    color: get-color("dark-green");
    font-family: $primary-font;
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
    background-color: transparent;
    padding: 12px 16px 13px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: get-color("orange");
    }

    @media screen and (max-width: $offcanvas-breakpoint) {
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      padding: 9px 20px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 188px;
    margin: 0 auto;
    padding-top: 19px;

    &--transparent,
    &--compact {
      display: none;
    }

    &--compact {
      max-width: 141px;
      top: 25px;

      @media screen and (max-width: $offcanvas-breakpoint) {
        max-width: 800px;
      }
    }

    img {
      width: 100%;
    }
  }

  &__top-nav {
    position: absolute;
    top: 27px;

    &--left {
      left: 115px;
    }

    &--right {
      right: 170px;
    }

    @media screen and (max-width: $offcanvas-breakpoint) {
      display: none;
    }

    ul {
      margin: 0;
      list-style-type: none;
    }

    li {
      display: inline;
      margin-left: 14px;

      &.active {
        a {
          img,
          svg {
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      font-family: $secondary-font-roman;
      color: get-color("dark-green");
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 10px;
      overflow: hidden;

      img,
      svg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 11px;
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &:active {
        color: get-color("dark-green");
        background-color: transparent;

        img,
        svg {
          clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
        }
      }
    }
  }

  //////////////////////
  // Styles that are applied to only the transparent header
  //////////////////////
  &--transparent {
    background-color: transparent;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    box-shadow: none;

    .header {
      &__open-off-canvas {
        svg {
          path,
          rect {
            stroke: #fff;
          }
        }
      }

      &__inner {
        height: 175px;
      }

      &__logo {
        max-width: 188px;

        &--transparent {
          display: block;
        }

        &--normal,
        &--compact {
          display: none;
        }
      }

      &__top-nav {
        a {
          color: #fff;

          img,
          svg {
            path {
              stroke: #fff;
            }
          }

          &:hover,
          &:active {
            color: #fff;
            background-color: transparent;

            img,
            svg {
              path {
                stroke-dashoffset: 0;
              }
            }
          }
        }
      }

      &__gift {

    @include breakpoint(medium) {
      display: none;
    }

    path {
      fill: #fff;
    }

    position: absolute;
    right: 30px;
    top: 30px;
    transform: scale(1.1);

      }

      &__open-booking {
        background-color: transparent;
        border-color: #fff;
        color: #fff;

        &:hover {
          color: get-color("dark-green");
          background-color: get-color("orange");
        }
      }
    }
  }

  &--compact {
    .header {

      &__inner {
        height: 83px;
      }

      &__logo {
        padding-top: 23px;
        max-width: 141px;

        &--compact {
          display: block;
        }

        &--normal,
        &--transparent {
          display: none;
        }
      }
    }
  }
}
