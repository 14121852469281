.slider {
  position: relative;
  text-align: center;

  .slider__inner {
    width: 100vw;
    height: 75vh;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  &--full-height {
    .slider__inner {
      width: 100vw;
      height: 100vh;

      @include breakpoint(small only) {
        height: calc(100vh - 59px);
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    picture {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe,
    video {
      position: absolute;
    }
  }

  &__caption {
    color: #fff !important;
    z-index: 1;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    padding: 0 18px 54px;
    width: 100%;

    @include breakpoint(medium) {
      width: 58.3%;
      text-align: left;
      padding: 0 0 80px 15%;
      margin: 0;
    }

    @include breakpoint(small only) {
      padding: 0 18px 150px;
    }

    &-wrapper {
      z-index: 2;
    }

    a {
      color: inherit;
    }

    h2 {
      @include slider-caption;
      color: #fff;
    }

    span {
      @include slider-caption-orange();
      display: block;

      @include breakpoint(small only) {
        display: inline-block;
      }
    }

    p {
      @include slider-copy;
      color: #fff;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.18) 66%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.45;
    z-index: 1;

    @include breakpoint(small only) {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.18) 66%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}
